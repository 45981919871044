import React from 'react';
import axios from 'axios';
import {Button, Header, Progress} from 'semantic-ui-react';

import ValidatedInput from '../../components/Forms/ValidatedInput';
import {
  passwordStrength,
  getPasswordValidationsPassed,
  getPasswordStrengthScore,
  passwordThresholds
} from '../../utils/PasswordStrength';
import {FORM_STATE} from '../../utils/FormState';

import Layout from '../../layouts/Layout';
import { withRouter } from '../../utils/useRouter';
import { withContexts } from '../../contexts';
import {Form} from 'formsy-semantic-ui-react';
import Modal from './../../components/Modal';
import PasswordValidationInfo from './PasswordValidationInfo';

import './ChangePassword.css';
import PasswordField from '../../components/Forms/PasswordField';

export class ChangePassword extends React.Component {

  state = {
    modalConfirmationOpen: false,
    submitStateFailMessage: '',
    passwordStrength: 0,
    passwordValidationsPassed: [],
    formState: FORM_STATE.DEFAULT,
    canSubmit: false
  }

  updatePasswordStrength = ({newPassword}) => {
    const validationsPassed = getPasswordValidationsPassed(newPassword);

    this.setState({
      passwordStrength: validationsPassed.length,
      passwordValidationsPassed: validationsPassed
    })
  }

  disableButton = () => {
    this.setState({canSubmit: false});
  }

  enableButton = () => {
    this.setState({canSubmit: true});
  }

  submit = (fields) => {
    const endpoint = process.env.REACT_APP_API_BASE_URI + 'identity/changePassword';
    const payload = {
      username: this.props.customer.customer.emailAddress,
      password: fields.password,
      newPassword: fields.newPassword,
      deviceInfo: this.props.app.deviceInfo
    };

    this.setState({formState: FORM_STATE.LOADING});

    axios
      .post(endpoint, payload, {ignoreGlobalErrors: true})
      .then((response) => {
        this.props.auth.updateToken(response.data.accessToken, false, false);
        this.setState({formState: FORM_STATE.DONE_LOADING, submitStateFailMessage: null});
		this
		  .props
		  .router
		  .navigate('/accountSettings');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.openModal();
          this.setState({formState: FORM_STATE.DEFAULT, submitStateFailMessage: 'You have entered an incorrect password. Please try again.'})
        } else {
          this.openModal();
          this.setState({
            formState: FORM_STATE.DEFAULT,
            submitStateFailMessage: (error.response.data && error.response.data.Message)
              ? error.response.data.Message
              : error.message || 'Password change failed, please try again.'
          });
        }
      });
  }

  openModal = () => {
    this.setState({modalConfirmationOpen: true});
  }

  closeModal = () => {
    if (this.state.submitStateFailMessage) {
      this.setState({modalConfirmationOpen: false});
    } else {
      this
        .props
        .router
        .navigate('/accountSettings');
    }
  }

  submitBtnText = () => {
    switch (this.state.formState) {
      case FORM_STATE.LOADING:
        return 'Password Updating';
      case FORM_STATE.DONE_LOADING:
        return 'Password Updated';
      default:
        return 'Save Changes';
    }
  }

  onFormChange = (fields) => {
    this.updatePasswordStrength(fields)
    this.setState({formTouched: true})
  }

  render() {
    const {
      formState,
      passwordStrength,
      passwordValidationsPassed,
      submitStateFailMessage,
    } = this.state;
    const { theme } = this.props.theme;

    return (
      <Layout pageTitle="Change password">

        <Modal
          name={`change-password/${submitStateFailMessage
          ? 'failure'
          : 'success'}`}
          size="tiny"
          open={this.state.modalConfirmationOpen}>
          <Modal.Header>Password Change:</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{(submitStateFailMessage)
                  ? submitStateFailMessage
                  : 'Your password has been changed'}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              fluid
              secondary
              style={{ backgroundColor: theme.primary.backgroundColor }}
              onClick={this.closeModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Header className="change-password-header" textAlign="center" size="large">
          Change password
        </Header>

        <PasswordValidationInfo validationsPassed={passwordValidationsPassed} />

        <Form
          noValidate
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          onChange={this.onFormChange}>

          <PasswordField
            required
            autoComplete="current-password"
            fluid
            name="password"
            label="Existing Password"
            hideErrorMessage/>
          <PasswordField
            validations={{
            minLength: 8,
            maxLength: 256,
            passwordStrength: passwordThresholds.WEAK,
			matchRegexp: /^[A-Za-z0-9@#$%^&*\-_!+=\[\]{}|:',.?\/`~"();]+$/
		}}
            validationErrors={{
            minLength: 'The new password field must be at least 8 characters.',
            maxLength: 'The new password field may not be greater than 16 characters.',
            matchRegexp: 'The new password field must only contain letters, numbers and symbols'
          }}
            required
            autoComplete="new-password"
            fluid
            name="newPassword"
            label="New Password"
            hideErrorMessage/>

          <div>
            <small>Password Strength
              <span style={{
                float: 'right'
              }}>{getPasswordStrengthScore(passwordStrength)}</span>
            </small>
            <Progress
              percent={passwordStrength * 25}
              size="small"
              error={passwordStrength < passwordThresholds.WEAK}
              warning={passwordStrength <= passwordThresholds.STRONG}
              success={passwordStrength <= passwordThresholds.EXCELLENT}/>
          </div>

          <PasswordField
            validations={{
            equalsField: 'newPassword'
          }}
            validationErrors={{
            equalsField: 'The confirm new password must match.'
          }}
            required
            autoComplete="new-password"
            fluid
            name="confirmPassword"
            label="Confirm New Password"/>

          <Button
            fluid
            secondary
            loading={formState === FORM_STATE.LOADING}
            style={{
              marginBottom: '1em',
              backgroundColor: theme.primary.backgroundColor
            }}
            type="submit"
            className={(formState === FORM_STATE.DONE_LOADING
            ? 'done-loading'
            : '')}
            disabled={!this.state.canSubmit}>
            {this.submitBtnText()}
          </Button>
        </Form>

      </Layout>
    )
  }
}

export default withRouter(withContexts(ChangePassword));
