import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Card, Segment, Header, Button, Loader } from "semantic-ui-react";
import { Navigate } from "react-router-dom";
import UsageDataHelper from "./Usage/UsageDataHelper";

import BusinessIcon from '../components/Icons/BusinessIcon';
import OffProductView from '../components/OffProductView/OffProductView'
import Layout from '../layouts/Layout';
import FullWidthLayout from '../layouts/FullWidthLayout';
import { PredictedAmount, ChargesSoFar, CurrentBillingPeriod } from '../components/Prediction/Prediction'
import { modalSearch, templateSearch, getModalForId } from "../utils/Modals";
import { Modal } from 'semantic-ui-react';
import PredictBill from '../model/PredictBill';

import { withCustomer } from '../contexts/Customer'
import { withApp } from '../contexts/App'
import { withTheme } from '../contexts/Theme'
import { SolidCard, SolidCardContent } from '../components/SolidCard/SolidCard';


export const BillPrediction = ({ ...props }) => {
	const { customerPremise, lastRefreshTimeString, pageConfig, customer: customer, hasLoaded } = props.customer;
	const { theme } = props.theme;

	const [isRefreshing, setIsRefreshing] = useState(false);
	const [prediction, setPrediction] = useState(null);
	const [modalCurrentCharges, setModalCurrentCharges] = useState(false);
	const [modal, setModal] = useState({
		isOpen: false,
		description: "",
		title: "",
		learnMore: ""
	});

	const dataHelper = useMemo(() => new UsageDataHelper(), []);

	useEffect(() => {
		makeCalculations(customerPremise);
	}, [customerPremise])



	function makeCalculations(customerPremise) {
		if (!customerPremise) return;
				
		return dataHelper.getBillingPeriodData(customerPremise.serviceAgreementID, customer.customerID)
			.then(data => {
				setPrediction(new PredictBill(data, customerPremise));
			});
		
	}


	function openModalCurrentCharges() {
		setModalCurrentCharges(true);
	}
	function closeModalCurrentCharges() {
		setModalCurrentCharges(false);
	}

	function openModal(modalId) {
		const modal = getModalForId(modalId);
		
		if (!modal) // error getting modal
			return;
		
		setModal({
			isOpen: true,
			description: modal.description,
			title: modal.title,
			lead: modal.lead,
			learnMore: modal.learnMoreBtn
		});
	}

	function closeModal() {
		setModal({
			isOpen: false
		});
	}

	function refreshPage() {
		setIsRefreshing(true);
		makeCalculations(customerPremise)
			.then(() => {
				setIsRefreshing(false);
			});
	}



	const isMobile = window.innerWidth < 991;
	const svgWidth = isMobile ? 240 : 340;
	const svgHeight = 100;
	

	if (!customerPremise) {
		return null;
	} else if (!prediction) {
		return 	<Loader
			inverted
			size="medium"
			style={{
				alignSelf: "center",
			}}
			active
		/>
	}



	//Case 604, added to check if a customer is meant to experience the off the product view (determined by API if all the SAs are inactive) but then to allow through a person with hub I and CCB A (fresh churn)
	if ((customerPremise && customerPremise.isActive) === false || (customer && customer.productLevel === "OFFPRODUCT")) {
		return (
			<Layout
				pageTitle="Predict a bill"
				layout={FullWidthLayout}
				isRefreshing={isRefreshing}
				onPullDownRefresh={refreshPage}
			>
				<OffProductView icon={(<BusinessIcon height="160px" />)} />
			</Layout>
		);
	}

	// NOTE: we have two different grids, 1 for mobile, 1 for tablet/desktop
	return (
		<Layout
			pageTitle="Predict a bill"
			layout={FullWidthLayout}
			isRefreshing={isRefreshing}
			onPullDownRefresh={refreshPage}
		>

			<Modal
				name={modal.title ? `predictbill/${modal.title.toLowerCase().replace(' ', '-')}` : false}
				size='tiny'
				open={modal.isOpen}
				onClose={closeModal}
				style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
				>
				<Modal.Header>{modal.title}</Modal.Header>
				<Modal.Content>
					<Modal.Description style={{
						textAlign: 'left'
					}}>{modal.description}</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						secondary
						fluid
						style={{ backgroundColor: theme.primary.backgroundColor }}
						onClick={closeModal}>OK</Button>
				</Modal.Actions>
			</Modal>

			<p style={{textAlign: 'center', marginBottom: '2rem', maxWidth: '30em', marginLeft: 'auto', marginRight: 'auto'}}>
				Note: Any payments, including Government bill relief payments, are not included in these figures
			</p>

			<Grid className="predictabill">

				<Grid.Column tablet={prediction.noData ? 16 : 8} computer={prediction.noData ? 16 : 8} only="tablet computer">
					<Card.Group stackable itemsPerRow={prediction.noData ? 1 : 1}>

						<PredictedAmount
							{...prediction}
							customerType={customer.customerType}
							openModal={openModal}
							theme={theme}
						/>
						{hasLoaded && <ChargesSoFar
							{...prediction}
							openModal={openModalCurrentCharges}
							handleCloseModal={closeModalCurrentCharges}
							open={modalCurrentCharges}
							premise={customerPremise}
							theme={theme}
						/>}
						
					</Card.Group>
				</Grid.Column>

				{!prediction.noData &&
					<Grid.Column tablet={8} computer={8} only="tablet computer">
						<Card.Group itemsPerRow={1}>

							<CurrentBillingPeriod
								{...prediction}
								width={svgWidth}
								height={svgHeight}
								openModal={openModal}
								theme={theme}
							/>

						</Card.Group>
					</Grid.Column>
				}

				<Grid.Column mobile={16} only="mobile">
					<Card.Group stackable itemsPerRow={1}>

						<PredictedAmount
							{...prediction}
							customerType={customer.customerType}
							openModal={openModal}
							theme={theme}
						/>
						<CurrentBillingPeriod
							{...prediction}
							width={svgWidth}
							height={svgHeight}
							openModal={openModal}
							theme={theme}
						/>
						{hasLoaded && <ChargesSoFar
							{...prediction}
							openModal={openModalCurrentCharges}
							handleCloseModal={closeModalCurrentCharges}
							open={modalCurrentCharges}
							premise={customerPremise}
							theme={theme}
						/>}

					</Card.Group>
				</Grid.Column>

			</Grid>

			{customerPremise && (
				<div style={{ paddingTop: '15px' }}>					
					<SolidCard style={{...theme.card.message, width: '100%', minHeight: '0' }}>
						<SolidCardContent>
							<div style={{textAlign: 'center'}}>Information last updated {customerPremise && lastRefreshTimeString}</div>
						</SolidCardContent>
					</SolidCard>
				</div>

			)}

		</Layout>

	);

}

export default withApp(withCustomer(withTheme(BillPrediction)))
