import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Button, Header, Item} from 'semantic-ui-react'
import axios from 'axios';

import Responsive from "../../components/Responsive/Responsive";
import {withApp} from '../../contexts/App';
import CustomerContext, {withCustomer} from '../../contexts/Customer';
import { withTheme } from '../../contexts/Theme';
import Layout from '../../layouts/Layout';
import ExternalLink from '../../components/ExternalLink';
import Log from '../../utils/Log';
import Urls from '../../utils/Urls';
import { SolidCard } from '../../components/SolidCard/SolidCard';

export class AccountSettings extends React.Component {

  state = {
    accountNumber: '',
    accountHolder: '',
    email: '',
    postalAddress: '',
    mobilePhoneNumber: '',
    homePhoneNumber: '',
    processing: true,
    abn: '',
  }

  componentDidMount() {
    this.getContactDetails();
  }

  componentDidUpdate = (nextProps) => {
    const { customerPremise } = this.props.customer;

    if (customerPremise && customerPremise !== nextProps.customer.customerPremise) {
      this.getContactDetails();
    }
  }

  getContactDetails = () => {
    const { customer, customerPremise } = this.props.customer;

    if (!customerPremise || !customerPremise.parentAccountID) return;

    //adding for APAYGDEV-61 , get concession from new end point
    axios
      .get(`${process.env.REACT_APP_API_BASE_URI}premises/HasConcession?accountID=${customerPremise.parentAccountID}&serviceAgreementID=${customerPremise.serviceAgreementID}`)
      .then((contact) => {
        this.setState({
          hasConcessions: contact.data === true
        })
      })
      .catch((error) => {
        Log.log('error ', error);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASE_URI}premises/contact?accountID=${customerPremise.parentAccountID}`)
      .then((contact) => {
        this.setState({
          accountNumber: customerPremise.parentAccountNumber,
          accountNumberCheckDigit: customerPremise.parentAccountNumberCheckDigit,
          accountHolder: customer.firstName + ' ' + customer.lastName,
          abn: customer.abn,
          email: customer.emailAddress,
          postalAddress: contact.data.PostalAddress.DisplayName,
          mobilePhoneNumber: contact.data.PhoneNumbers.MobilePhoneNumber && contact
            .data
            .PhoneNumbers
            .MobilePhoneNumber
            .split('-')
            .join(' '),
          homePhoneNumber: contact.data.PhoneNumbers.HomePhoneNumber && contact
            .data
            .PhoneNumbers
            .HomePhoneNumber
            .split('-')
            .join(' ')
            .replace(')', ') '),
          processing: false,
            
        })

      })
      .catch((error) => {
        Log.log('error ', error);
      });
        
  }

  render() {
    const {
      hasConcessions,
      accountNumber,
      accountHolder,
      accountNumberCheckDigit,
      email,
      postalAddress,
      mobilePhoneNumber,
      homePhoneNumber,
      abn
    } = this.state;

    

    const { pageConfig, customer } = this.props.customer;
    const { theme } = this.props.theme;
    const concessionsMessage = customer && ((customer.isFromCCB && hasConcessions) || (customer.isFromHubCX && customer.customerType == "residential" && (hasConcessions || customer.lifeSupport))) ? "Concessions applied to this account." : "No Concessions applied to this account.";
    const accountNumberWithCheckDigit = `${accountNumber || ''}${accountNumberCheckDigit || ''}`;


	return (
      <Layout 
      pageTitle="Account details">
        <Responsive
          greaterThan="mobile"
          as={Header}
          textAlign="center"
          size="large"
          style={{
          marginBottom: '1.2em'
        }}>
          Account details
        </Responsive>

        <Item.Group className="notifications" >
          <Item className="notification">
            <Item.Content>
              <Item.Header style={theme.notification.header}>Account Details</Item.Header>
              <Item.Description style={theme.notification.description}>
                <p>
                  <b>Account Number</b><br/>
                  { accountNumberWithCheckDigit }
                </p>
                <p>
                  <b>Primary Account Holder</b><br/>
                  {accountHolder}
                </p>
                {abn &&
                  <p>
                    <b>ABN</b><br />
                    {abn}
                  </p>
                }
                <br/>
                
                {pageConfig && pageConfig.productName !== 'business' &&
                <span>
                <p>
                  <b>{concessionsMessage}</b>
                </p>
                <br/>
                </span>
                }
                <p>
                  <b>BPay</b>
                  <br/>
                  Biller Code: 5595 <br />
                  Reference Number: { accountNumberWithCheckDigit }
                </p>

                <br />

                <p>For other payment options&nbsp;
                  <ExternalLink
                    style={{ color: theme.primary.color }}
                    href={Urls.PAYMENT_OPTIONS}>go here</ExternalLink>
                </p>
                <p>
                  Allow for processing times for these payment options to be reflected in the aurora+ app.
                </p>

              </Item.Description>
            </Item.Content>
          </Item>
          <Item className="notification">
            <Item.Content>
              <Item.Header style={theme.notification.header}>Your Contact Details</Item.Header>
              <Item.Description style={theme.notification.description}>
                <p>
                  <b>Email Address</b><br/> {email}</p>

                <p>
                  <b>Postal Address</b><br/> {postalAddress}<br/>
                  <NavLink
                    style={{ color: theme.primary.color }}
                    to="/accountSettings/accountDetails/editAddress">
                    <b>Edit</b>
                  </NavLink>
                </p>

                <p>
                  <b>Mobile Number</b><br/> {mobilePhoneNumber}<br/>
                  <NavLink
                    style={{ color: theme.primary.color }}
                    to="/accountSettings/accountDetails/editPhoneNumbers">
                    <b>Edit</b>
                  </NavLink>
                </p>

                <p>
                  <b>{pageConfig && pageConfig.productName === 'business' ? 'Work Phone Number' : 'Home Phone Number'}</b><br/> {homePhoneNumber}<br/>
                  <NavLink
                    style={{ color: theme.primary.color }}
                    to="/accountSettings/accountDetails/editPhoneNumbers"
                  >
                    <b>Edit</b>
                  </NavLink>
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>

		<Button
			size="small"
			style={{
				display: 'inline-block',
				marginTop: "4em",
				marginBottom: "1em"
			}}
			fluid
			secondary
			as={Link}
			to="/accountSettings/requestDeletion"
		>
			Delete my aurora+ account
		</Button>
      </Layout>
      
    )
  }
}

export default withApp(withCustomer(withTheme(AccountSettings)));

