import React from 'react';
import {
  Button,
  Message
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import Modal from './../../components/Modal';

const modals = {
  ErrorModal: {
    open: false,
    header: 'Oops!',
    render: false,
    name: 'payment/error',
    content: (state, modalState) => (
      <Modal.Description style={{ textAlign: 'center' }}>
        <p>There was an unexpected error with your request. Please try again.</p>
        <p>If the error persists, contact Aurora Energy by clicking on Contact us in your app menu or calling 1300 132 030.</p>
      </Modal.Description>
    ),
  },
    mPaymentConfirm: {
      open: false,
      header: 'Confirm payment',
      render: false,
      name: 'payment/confirm',
      content: (state, modalState) => (
        <Modal.Description style={{
          textAlign: 'center'
        }}>
          You're about to pay <b>${(state.selectedTopUpOption || parseFloat(modalState.amount)).toLocaleString('en-AU', {
            currency: 'AUD',
            minimumFractionDigits: 2
          })}</b> into your aurora+ account
        </Modal.Description>
      ),
    },
    mPaymentSuccess: {
      open: false,
      header: 'Payment successful',
      name: 'payment/success',
      content: (state, modalState, props) => (
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>Your payment of <b>{modalState.TotalAmountDisplay}</b> has been received.</p>
          <p>A receipt no. {modalState.PaymentNumber} has been sent to your email inbox.</p>
        </Modal.Description>
      ),
    },
    mPaymentPending: {
      open: false,
      header: 'Pending Payment',
      name: 'payment/pending',
      content: (state, modalState, props) => (
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>
            Your payment of {modalState.TotalAmountDisplay} has been received and will
            be applied to your account within the next 24 hours.
          </p>
        </Modal.Description>
      ),
    },
    mPaymentFailure: {
      open: false,
      header: 'Payment Failed',
      name: 'payment/failure',
      content: (state, modalState, props) => (
        <Modal.Description style={{
          textAlign: 'center'
        }}>
          <p>Your payment of ${(state.selectedTopUpOption || parseFloat(modalState.amount)).toLocaleString('en-AU', {
              currency: 'AUD',
              minimumFractionDigits: 2
            })} has been rejected.</p>
          {modalState.error && <Message error>{modalState.error.message}</Message>}
        </Modal.Description>
      ),
    },
    mPaymentSuccessCardSaveFailure:{
      open: false,
      header: 'Payment Successful',
      name: 'payment/success-card-save-fail',
      content: (state, modalState, props) => (
        <Modal.Description style={{textAlign:'center'}}>
          <p>Your payment of {modalState.TotalAmountDisplay} has been received, but your card details have not been saved.</p>
          <p>To save card details for future use, please go to Menu &gt; Account management &gt; Card details</p>
          <p>Saved cards will then appear on the Make payment screen for future payments.</p>
        </Modal.Description>
      ),
  },
  mPaymentConfirmPostPay: {
    open: false,
    header: 'Confirm Payment',
    render: false,
    name: 'payment/confirmPostPay',
    content: (state, modalState) => (
      <Modal.Description style={{ textAlign: 'center' }}>
        You're about to pay Bill No. {modalState.billId} for the amount of ${(parseFloat(modalState.amount)).toLocaleString('en-AU', {
          currency: 'AUD',
          minimumFractionDigits: 2
        })}
      </Modal.Description>
    ),
  },
  mPaymentSuccessPostPay: {
    open: false,
    header: 'Payment Successful',
    name: 'payment/successPostPay',
    content: (state, modalState, props) => (
      <Modal.Description style={{ textAlign: 'center' }}>
        <p>Your payment of {modalState.TotalAmountDisplay} has been received.</p>
        <p>Receipt No.{modalState.PaymentNumber} has been sent to your email.</p>
      </Modal.Description>
    ),
  },
}

const defaultActionsCreator = closeModal => ({
  ErrorModal: (state, modalState) => [(
    <Button key={1} fluid basic onClick={x => closeModal('ErrorModal')}>Close</Button>
  )],
  mPaymentConfirm: (state, modalState) => [(
    <Button key={0} style={modalState.style} fluid secondary onClick={modalState.onConfirm}>Confirm</Button>
  ), (
    <Button key={1} fluid basic onClick={x => closeModal('mPaymentConfirm')}>Cancel</Button>
  )],
  mPaymentSuccess: (props, modal, { history }) => [(
      <Button key={0} style={modal.style} fluid secondary as={Link} to="/" replace>Go to home</Button>
  )],
  mPaymentPending: (props, modal, { history }) => [(
    <Button key={0} style={modal.style} fluid secondary as={Link} to="/refresh/payment">Make Another Payment</Button>
  ), (
    <Button key={1} fluid basic as={Link} to="/" replace>Go To Home</Button>
  )],
  mPaymentFailure: (state, modalState, props) => [(
    <Button key={0} style={modalState.style} fluid secondary as={Link} to="/refresh/payment">Try Again</Button>
  ), (
    <Button key={1} fluid basic as={Link} to="/refresh/accountSettings/payments">Manage Payment Details</Button>
  )],
  mPaymentSuccessCardSaveFailure: (props, modal, { history }) => [
    <Button key={0} style={modal.style} fluid secondary as={Link} to="/refresh/payment">Make Another Payment</Button>,
    <Button key={1} fluid basic as={Link} to="/" replace>Go To Home</Button>
  ],
  mPaymentConfirmPostPay: (state, modalState) => [(
    <Button key={0} style={modalState.style} fluid secondary onClick={modalState.onConfirm}>Confirm</Button>
  ), (
    <Button key={1} fluid basic onClick={x => closeModal('mPaymentConfirmPostPay')}>Cancel</Button>
    )],
  mPaymentSuccessPostPay: (props, modal, { history }) => [(
    <Button key={0} style={modal.style} fluid secondary as={Link} to="/refresh/payment">Make Another Payment</Button>
  ), (
    <Button key={1} fluid basic as={Link} to="/" replace>Go To Home</Button>
  )],
});

const restrictedNavigationActionsCreator = closeModal => ({
  mPaymentConfirm: (state, modalState) => [
    (<Button key={0} style={modalState.style} fluid secondary onClick={modalState.onConfirm}>Confirm</Button>),
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentConfirm')}>Cancel</Button>)
  ],
  mPaymentSuccess: (props, modal, {history}) => [
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentSuccess')}>Ok</Button>)
  ],
  mPaymentPending: (props, modal, {history}) => [
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentPending')}>Ok</Button>)
  ],
  mPaymentFailure: (state, modalState, props) => [
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentFailure')}>Ok</Button>)
  ],
  mPaymentSuccessCardSaveFailure: () => [
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentSuccessCardSaveFailure')}>Ok</Button>)
  ],
  mPaymentConfirmPostPay: (state, modalState) => [
    (<Button key={0} style={modalState.style} fluid secondary onClick={modalState.onConfirm}>Confirm</Button>),
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentConfirmPostPay')}>Cancel</Button>)
  ],
  mPaymentSuccessPostPay: (props, modal, { history }) => [
    (<Button key={1} fluid basic onClick={x => closeModal('mPaymentSuccessPostPay')}>Ok</Button>)
  ],
});

export default ({ closeModal, restrictedNavigation }) => {
  let actions;

  if (restrictedNavigation) {
    actions = restrictedNavigationActionsCreator(closeModal)
  } else {
    actions = defaultActionsCreator(closeModal)
  }

  const allModals = {};

  Object.keys(modals).forEach(key => {
    allModals[key] = {
      ...modals[key],
      content: modals[key].content,
      actions: actions[key]
    }
  });

  return allModals;
}
