import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import {
  Button,
  Form,
  Confirm,
  Dimmer,
  Loader,
  Segment,
  Header
} from 'semantic-ui-react'
import WestpacAccountList from './../../../components/WestpacAccountList/WestpacAccountList';

import Layout from '../../../layouts/Layout';
import { withRouter } from '../../../utils/useRouter';
import {withCustomer} from '../../../contexts/Customer'
import Modal from './../../../components/Modal';

export class PaymentSettingsList extends React.Component {

  state = {
    loading: false,
    errors: [],
    accounts: [],
    error: false,
    confirmDelete: false
  }

  componentDidMount() {}

  removeCard = async(accountToken) => {
    this.setState({loading: true})

    await axios.delete(process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/' + this.state.editing.accountToken)

    this
      .props
      .router
      .navigate('/accountSettings/payments')
  }

  render() {
    return (
      <Layout pageTitle="Card details">

        <Header textAlign='center' size='large'>Card details</Header>
        <Modal
          name="payment-settings/list/error"
          size="tiny"
          open={!!this.state.error}
          onClose={x => this.setState({error: false})}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{this.state.error}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={x => this.setState({error: false})}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
        <Confirm
        open={!!this.state.confirmDelete}
        content="Are you sure you want to delete this card from your saved payment options?"
        cancelButton={{ content: 'Cancel' }} 
        confirmButton={{
          content: 'Delete Card',
          className: 'confirm-button'
        }}
        onCancel={() => this.setState({ confirmDelete: null })}
        onConfirm={() => {
        this.removeCard(this.state.confirmDelete);
        this.setState({ confirmDelete: null });
        }}
        />
        <Form as='div'>
          <Dimmer inverted active={this.state.loading}>
            <Loader/>
          </Dimmer>
          <WestpacAccountList
            selectable={false}
            showAllCards={true}
            onLoaded={accounts => {
            this.setState({accounts})
          }}
            onError={x => {}}>

            {this.state.accounts && this.state.accounts.length === 0 && <Segment basic textAlign="center">
              You have no saved cards.
            </Segment>}

            <Button
              as={Link}
              to="/accountSettings/payments/add"
              secondary
              fluid
              disabled={this.state.accounts.length >= 4}>
              Add a Card
            </Button>
          </WestpacAccountList>

        </Form>
      </Layout>
    )
  }
}

export default withRouter(withCustomer(PaymentSettingsList))
