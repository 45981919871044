import React from 'react';
import {Button, Header, Modal} from 'semantic-ui-react'
import axios from 'axios';

import Responsive from "../../components/Responsive/Responsive";
import {withApp} from '../../contexts/App';
import {withCustomer} from '../../contexts/Customer';
import { withTheme } from '../../contexts/Theme';
import Layout from '../../layouts/Layout';
import ExternalLink from '../../components/ExternalLink';

import "./RequestDeletion.css";
import { FORM_STATE } from '../../utils/FormState';
import PasswordField from '../../components/Forms/PasswordField';
import { Form } from 'formsy-semantic-ui-react';

const MOVE_OUT_LINK = "https://www.auroraenergy.com.au/residential/move-out";
const TERMS_CONDITIONS_LINK = "https://www.auroraenergy.com.au/residential/products/residential-all-prices";


export class RequestDeletion extends React.Component {

  state = {
    isTouched: false,
    modalSubmitOpen: false,
    formState: FORM_STATE.DEFAULT,
    fields: {
      password: "",
      remember: true
    },
    canSubmit: false,
	showRequestError: false,
  }


  // const email = this.props.customer.email

  onFormValid = () => {
    this.setState({ canSubmit: true });
  };

  onFormInvalid = () => {
    this.setState({ canSubmit: false });
  };


  submit = payload => {
    const endpoint = process.env.REACT_APP_API_BASE_URI + "identity/SendDeleteAccountRequest";

    this.setState({ canSubmit: false, formState: FORM_STATE.LOADING });

	// Hardcode the username since it is known. Not actually required for this route, but tidy
	payload = {
		...payload,
		username: (this.props.customer.customer || {}).emailAddress
	}

    axios
      .post(endpoint, payload, { ignoreGlobalErrors: true })
      .then(response => {
        this.setState({ formState: FORM_STATE.DONE_LOADING });
		this.openModal();
      })
      .catch(error => {
        this.setState({ canSubmit: true, formState: FORM_STATE.DEFAULT, showRequestError: true });        
      });
	  
  };


  openModal = () => {
    this.setState({ modalSubmitOpen: true });
  };

  closeModal = () => {
    this.setState({ modalSubmitOpen: false });
  };

  completeLogout = () => {
	this.props.app.clearAllUserData();
  }


  renderForm = () => (
	<React.Fragment>
		<PasswordField
			hideErrorMessage
			autoComplete="current-password"
			name="password"
			placeholder="Password"
			style={{
				marginBottom: '0.5em'
			}}
		/>
		<Form.Field>
			<Button
				type="submit"
				fluid
				secondary
				disabled={!this.state.canSubmit}
				size="small"
				loading={this.state.formState === FORM_STATE.LOADING}
			>
				Delete my aurora+ account
			</Button>
		</Form.Field>		
	</React.Fragment>
  );	

  renderContent = () => {

    const { customer } = this.props.customer;

    return (
		<React.Fragment>
			<Responsive
				greaterThan="mobile"
				as={Header}
				textAlign="center"
				size="large"
				style={{
				marginBottom: '1.2em'
			}}>
				Account details
			</Responsive>

			<div className="request-deletion">
				<h2
					className="ui medium header"
					style={{
						marginBottom: '1.2em'
					}}
				>
					We are sorry to see you go...
				</h2>

				<section>
					<strong>What will change...</strong>
					<ul>
						<li>we will move you from the aurora+ product offer to our standard offer</li>
						<li>you will no longer have access to aurora+ services, which includes:
							<ul>
								<li>usage data and graphs,</li>
								<li>on-line bill viewing,</li>
								<li>notifications, and</li>
								<li>in-app payments.</li>
							</ul>
						</li>
					</ul>
				</section>

				<section>
					<strong>What won't change...</strong>
					<ul>
						<li>your tariff</li>
						<li>monthly e-billing (unless you contact us)</li>
						<li>
							the connection to your property, if you are requiring a move-out (please fill out&nbsp;
							<ExternalLink href={MOVE_OUT_LINK}>this</ExternalLink>
							&nbsp;form)
						</li>
					</ul>
				</section>

				<section>
					<strong>By proceeding, you are accepting:</strong>
					<ul>
						<li>the standard offer <ExternalLink href={TERMS_CONDITIONS_LINK}>terms and conditions</ExternalLink></li>
						<li>that we will log you out of the aurora+ app and</li>
						<li>delete your aurora+ login</li>
					</ul>
				</section>

				
				{ this.state.showRequestError ?
					<p className="error-message">
						<strong>We cannot proceed with deletion of your aurora+ account. Please try entering your password again.</strong>
					</p>
				:
					<p>
						<strong>Please re-enter your password and click the button below to confirm you want us to delete your aurora+ account:</strong>
					</p>
				}

				{ this.renderForm() }

			</div>
		</React.Fragment>
	);
  }

  render() {
	return (
	  <Layout pageTitle="Account details">

		<Modal
          name="request/success"
          size="tiny"
          open={this.state.modalSubmitOpen}
          onClose={this.closeModal}
        >
          <Modal.Header>You have requested deletion of your aurora+ account</Modal.Header>
          <Modal.Content>
            <Modal.Description>
				<p>
					This will be actioned within the next 10 business days
					and you will receive an email once complete.
				</p>
				<p>
					You will now be logged out of aurora+.
				</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="orange" fluid onClick={this.completeLogout}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
		
		<Form
          noValidate
          onValidSubmit={this.submit}
          onValid={this.onFormValid}
          onInvalid={this.onFormInvalid}
          className="minimal"
          style={{
            width: "100%"
          }}
          loading={this.state.processing}
        >
          {this.renderContent()}
        </Form>

      </Layout>
    )
  }
}

export default withApp(withCustomer(withTheme(RequestDeletion)));

